<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title>
        Ver usuario

        <v-spacer></v-spacer>
      </v-card-title>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-container fluid>
      <div v-if="user_loading">
        <v-col>
          <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
        </v-col>
      </div>

      <div v-else>
        <div v-if="user_error != null">
          <v-col>
            <v-alert text outlined color="deep-orange" icon="mdi-fire">
              {{ user_error }}
            </v-alert>
          </v-col>
        </div>

        <div v-else>
          <v-form>
            <v-row>
              <v-col cols="12" md="8">
                <v-card>
                  <v-card-title>
                    Información
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.username"
                          label="Nombre de usuario"
                          clearable
                          disabled
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.role"
                          label="Role"
                          clearable
                          disabled
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.count"
                          label="Cantidad"
                          clearable
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title>
                    Acciones disponibles
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text> Por favor selecciona una opción </v-card-text>

                  <v-card-actions>
                    <v-btn color="error" @click="closeForm()">
                      <v-icon>mdi-close</v-icon>
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "UserView",
  components: {},
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Usuarios",
        disabled: false,
        href: "/user/index",
      },
      {
        text: "Ver",
        disabled: true,
        href: "/user/view",
      },
    ],
    // Form Data

    user_loading: true,
    user_data: null,
    user_error: null,
  }),
  mounted() {
    this.valityUser();
    this.getUser();
  },
  methods: {
    valityUser(){
      let role = this.$store.getters.getUser.role;

      if (role != 'admin') this.$router.push("/register/index");
    },
    getUser() {
      this.user_loading = true;

      this.$axios
        .get(
          "https://fiel.compratec.com.mx/backend/public/api/user/" +
            this.$route.params.id
        )
        .then((response) => {
          this.user_data = response.data;

          if (this.users_data === ""){
            this.user_error = "El usuario no existe, por favor de ingresar un identificador correcto.";
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.user_loading = false));
    },
    closeForm() {
      this.$router.push("/user/index");
    },
  },
  watch: {},
};
</script>

